<template>
    <!--    <div class="overlay-body">-->
    <b-overlay class="overlay-body" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
        <slot name="body"/>
    </b-overlay>
    <!--    </div>-->
</template>

<script>
// @group GENERAL
export default {
    name: "ComponentOverlay",
    components: {},
    props: {
        delay: {
            type: Number,
            default: 500
        }
    },
    data() {
        return {
            /**
             * @vuese
             * Turn on/off overlay when process is busy.
             */
            overlay: {
                visible: false,
                opacity: 0.7,
            },
        }
    },
    mounted() {
    },
    methods: {
        show() {
            setTimeout(() => {
                if (this.$root.mx_busy) {
                    this.overlay.visible = true;
                }
            }, this.delay);
        },
        hide() {
            this.overlay.visible = false;
        },
    },
    computed: {},
    filters: {},
    watch: {
        '$root.mx_busy': {
            handler: function (value, oldValue) {
                if (value) {
                    this.show();
                    return;
                }
                this.hide();
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.overlay-body {
    height: 100%;
}
</style>
