<template>
    <div class="mb-1" :style="{'height': __layoutCenterHeightCalculate.px}">
        <component-overlay>
            <template #body>
                <div>
                    <!-- toolbar -->
                    <b-button-toolbar
                        class="my-1"
                    >
                        <!-- button create-route -->
                        <b-button class="mr-1" size="sm"
                                  :variant="$route.path === '/_adventures2/create-route' ? 'secondary' : 'primary'"
                                  to="/_adventures2/create-route"
                        >
                            {{$t('adventure.sider_experience.new_adventure')}}
                        </b-button>
                        <!-- button adventures -->
                        <b-button class="mr-1 " size="sm"
                                  :variant="$route.path === '/_adventures2' ? 'secondary' : 'primary'"
                                  to="/_adventures2"
                        >
                            {{$t('adventure.sider_experience.adventures')}}
                        </b-button>
                        <!-- button book -->
                        <b-button v-if="bookingEnabled" class="mr-1" size="sm"
                                  :variant="$route.path === '/_adventures2/booked' ? 'secondary' : 'primary'"
                                  to="/_adventures2/booked"
                        >
                            {{$t('adventure.sider_experience.booked')}} ({{$root.mx_countBooked}})
                        </b-button>
                        <b-button v-else class="mr-1" size="sm"
                                  :variant="'light'"
                                  style="cursor: default"
                                  :title="$t('adventure.route.noLogin_message.book')"
                        >
                            {{$t('adventure.sider_experience.booked')}}
                        </b-button>
                    </b-button-toolbar>
                    <!-- router view -->
                    <router-view></router-view>
                </div>
            </template>
        </component-overlay>
    </div>
</template>

<script>
import Vue from "vue";
import ComponentOverlay from "@/components/_public/_adventures2/elements/ComponentOverlay";

Vue.prototype.$adventuresSubHeight = 35;

export default {
    name: "Adventures2Main",
    components: {ComponentOverlay},
    props: {},
    data() {
        return {
            uri: {
                main: `api/v1/adventure2`,
            },
        }
    },
    mounted() {
        this.loadBookedCount();
    },
    methods: {
        // <-- base -->
        loadBookedCount() {
            if (!this.$root.mx_isLogin) {
                this.$root.mx_countBooked = 0;
                return;
            }
            // <--  -->
            this.$root.mx_busy = true;
            this.adventures = {};
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            // this.axios.get(this.uri.main, config)
            this.axios.get(this.uri.main + '/booked_count', config)
                .then((response) => {
                    this.$root.mx_countBooked = response.data;
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
            this.routes = [];
        },
    },
    computed: {
        /**
         * @vuese
         * Check if user login for mode bookings
         */
        bookingEnabled() {
            return this.$root.mx_isLogin;
        },
    },
    filters: {},
    watch: {
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                if (value) {
                    this.loadBookedCount();
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
